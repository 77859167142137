<template>
  <section class="email-token-expired">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('email.token_expired')}}
      </div>
    
      <div class="text-n-md font-semibold mt-n-2xl">
        {{$t('email.your_confirmation_token_expired')}}
      </div>
    
      <div class="text-n-md font-semibold mt-n-xl">
        {{$t('email.please_request_a_new_token')}}
      </div>
    </div>

    <ButtonV2
      wide
      testId="btn-resend-email"
      :label="$t('email.resend_email')"
      :inactive="isLoading"
      @onClick="resendConfirmationEmail"
    />
  </section>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';
  import RESEND_EMAIL_CONFIRMATION from '@/graphql/mutations/ResendEmailConfirmation.gql';

  export default {
    name: 'EmailTokenExpired',
    components: {
      ButtonV2,
    },

    data() {
      return {
        isLoading: false,
      };
    },

    async mounted() {
      await this.$store.dispatch('events/track', {
        event: 'EMAIL_CONFIRMATION_VIEWED',
      });
    },

    methods: {
      async resendConfirmationEmail() {
        try {
          this.isLoading = true;

          await this.apolloApiCall({
            mutation: RESEND_EMAIL_CONFIRMATION,
          });

          await this.$store.dispatch('events/track', {
            event: 'EMAIL_CONFIRMATION_SENT',
          });

          this.showSuccess(this.$t('success.confirmation_email_sent'));

          this.hideModal();
        } catch (err) {
          await this.showError(err);
        } finally {
          this.isLoading = false;
        }
      }
    }
  };
</script>

<style scoped>
.email-token-expired {
  @apply flex flex-col justify-between;
  min-height: 400px;
}
</style>
